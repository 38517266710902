<template>
    <div v-if="items.links && items.links.length > 3" class="pagination">
        <ul class="pagination__list">
            <li v-if="items.current_page > 3" class="pagination__item" >
                <a class="pagination__link" :href="items.first_page_url">1</a>
            </li>

            <li class="pagination__list">
                <div v-if="items.current_page > 4" class="pagination__link">...</div>
            </li>

            <template v-for="(link, key) in items.links">
                <li v-if="showLink(link)" class="pagination__list">
                    <a :key="key" class="pagination__link" :class="{ 'is-active': link.active }"
                                  :href="link.url"> {{link.label}} </a>
                </li>
            </template>

            <li class="pagination__list">
                <div v-if="items.current_page < items.last_page - 3" class="pagination__link" >...</div>
            </li>

            <li v-if="items.current_page < items.last_page - 2" class="pagination__list">
                <a class="pagination__link" :href="items.last_page_url" >{{items.last_page}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        items: Object,
    },
    methods: {
        showLink(link) {
            if (link.url !== null && link.label >= this.items.current_page - 2 && link.label <=
                this.items.current_page + 2) {
                if ((link.label.indexOf('&laquo;') === -1) && (link.label.indexOf('&raquo;') === -1)) {
                    return true;
                }
            }
        }
    },
};
</script>
