<template>
  <div class="main__wrap">
    <div class="main__content content">
      <div class="content__top content__top_link">
        <div class="title">Шаблоны задач</div>

        <a href="/settings/patterns/create" class="btn btn--4 btn--m btn--primary">
          <span class="btn__label">+ Добавить шаблон</span>
        </a>
      </div>
      <div v-if="patternsList && patternsList.data.length > 0" class="content__main">
        <div class="content__body">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
              <tr>
                <th>Название</th>
                <th>Периодичность</th>
                <th>Статус</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="pattern in patternsList.data">
                <td>
                  <a :href="'/settings/patterns/' + pattern.id" class="col-main col-link">{{pattern.name}}</a>
                </td>
                <td><span class="col-extra">{{pattern.repeat}}</span></td>
                <td><span class="col-extra">{{pattern.status}}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="patternsList && patternsList.data.length === 0" class="content__main content__main--center">
        <div class="content__body">
          <div class="plug-box">
            <div class="plug-box__cover">
              <img src="/img/plug-no-tasks.svg" width="574" height="231" alt="" class="plug-box__img">
            </div>

            <div class="plug-box__text">Шаблонов задач нет</div>
          </div>
        </div>
      </div>

      <div v-if="patternsList" class="content__bottom">
        <Pagination :items="patternsList"></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../Pagination/Pagination.vue';
import packageInfo from '../../../../package.json';
import {debounce} from '../_Functions/debounce';

export default {
  components: {
    Pagination,
  },
  props: {
    patterns: Array
  },
  data() {
    return {
      appVersion: packageInfo.version,
      patternsList: null,
      search: null,
      debounceFunction: null,
    }
  },
  mounted() {
    this.patternsList = this.patterns;
    const urlParams = new URLSearchParams(window.location.search);
    this.search = urlParams.get('search');

    this.debounceFunction = debounce(() => this.searchPatterns(), 500)
  },
  methods: {
    searchPatternsKeyUp() {
      this.debounceFunction();
    },
    searchPatterns() {
      let url = new URL(location.href);
      let searchParams = url.searchParams;
      if (this.search) {
        searchParams.set('search', this.search);
      } else {
        searchParams.delete('search');
      }
      searchParams.set('page', '1');
      url.search = searchParams.toString();
      location.href = url.toString();
    }
  }
};
</script>