<template>
  <div class="task-comments">
    <div ref="comments" class="task-comments__list">
      <div v-for="comment in commentsList" :id="'comment_' + comment.id" :ref="'comment_' + comment.id" class="task-comments__item task-comment">
        <div class="task-comment__top">
          <div class="task-comment__ava">
            <picture>
              <img v-if="comment.user && comment.user.photo" :src="comment.user.photo" width="32" height="32" alt="">
              <img v-if="!comment.user" src="/img/no-photo.jpg" width="32" height="32" alt="">
            </picture>
          </div>
          <div class="task-comment__name" v-if="comment.user && comment.user.name">{{comment.user.name}}</div>
          <div class="task-comment__name" v-if="!comment.user">Удалённый пользователь</div>
          <div class="task-comment__date task-date">{{comment.date}}</div>
          <div v-if="comment.delete || comment.edit" class="task-comment__controls">
            <div v-if="comment.edit" class="task-comment__control task-comment__edit">
              <button @click="editComment(comment)" class="btn btn--4 btn--s btn--gray btn--icon" type="button">
                <span class="btn__icon">
                  <span class="icon icon--s">
                    <svg class="svg" width="16" height="16">
                      <use :href="'/img/sprite-' + appVersion + '.svg#edit-16'"/>
                    </svg>
                  </span>
                </span>
              </button>
            </div>

            <div v-if="comment.delete" class="task-comment__control task-comment__delete">
              <button @click="deleteComment(comment.id)" class="btn btn--4 btn--s btn--gray btn--icon" type="button">
                <span class="btn__icon">
                  <span class="icon icon--s">
                    <svg class="svg" width="16" height="16">
                      <use :href="'/img/sprite-' + appVersion + '.svg#delete-16'"/>
                    </svg>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="task-comment__main">
          <div v-if="comment.answer_to && comment.parent" class="task-comment__answer">
            <a :href="'#comment_' + comment.answer_to" class="comment-answer">
              <div class="comment-answer__top">
                <div class="comment-answer__name">{{comment.parent.user.name}} <span class="comment-answer__date">{{comment.parent.date}}</span></div>
              </div>

              <div class="comment-answer__main" v-html="wrapAnchorTag(comment.parent.text, skipAnchor = true)"></div>
            </a>
          </div>
          <div class="task-comment__text text" v-html="wrapAnchorTag(comment.text)"></div>
        </div>
        <div class="task-comment__bottom">
          <div class="task-comment__files list list--sm">
            <div v-for="file in comment.documents" class="list__item">
              <div class="file-item">
                <a v-if="!file.image" target="_blank" :href="file.preview" :download="file.name" class="file-item__main file-item__main--main">
                  <div class="file-item__cover">
                    <svg class="svg-clr" width="32" height="32">
                      <use :href="'/img/sprite-clr-' + appVersion +'.svg#file-' + file.extension + '-32'"/>
                    </svg>
                  </div>
                  <div class="file-item__info">
                    <div class="file-item__name">{{file.name}}</div>
                    <div class="file-item__description">
                      <strong>{{file.size}}</strong>
                    </div>
                  </div>
                </a>
                <a v-if="file.image" @click="showImage(file)" style="cursor: pointer;" class="file-item__main file-item__main--preview">
                  <div class="file-item__preview">
                    <div class="file-preview" :style="{'background-image': 'url(' + file.preview +')'}">
                      <img :src="file.preview" alt="">
                    </div>
                  </div>
                  <div class="file-item__cover">
                    <svg class="svg-clr" width="32" height="32">
                      <use :href="'/img/sprite-clr-' + appVersion +'.svg#file-' + file.extension + '-32'"/>
                    </svg>
                  </div>
                  <div class="file-item__info">
                    <div class="file-item__name">{{file.name}}</div>
                    <div class="file-item__description">
                      <strong>{{file.size}}</strong>
                    </div>
                  </div>
                </a>
                <div class="file-item__extra">
                  <a :href="file.download" :download="file.name" class="file-item__btn">
                    <span class="icon icon--s">
                      <svg class="svg" width="16" height="16">
                        <use :href="'/img/sprite-' + appVersion + '.svg#download-16'"/>
                      </svg>
                    </span>
                  </a>
                  <button v-if="comment.delete" @click="deleteFile(file)" class="file-item__btn" type="button">
                    <span class="icon icon--s">
                      <svg class="svg" width="16" height="16">
                        <use :href="'/img/sprite-' + appVersion + '.svg#delete-16'"/>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="task-comment__bottom-btn">
            <a @click="selectAnswerTo(comment.id)" style="cursor: pointer" class="link link--main">Ответить</a>
          </div>
        </div>
      </div>
    </div>
    <vue-easy-lightbox :visible="visibleRef" :imgs="imgsRef" :index="indexRef" @hide="closeImage"></vue-easy-lightbox>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';
import Echo from "laravel-echo";
import {ref} from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox'
import VueCookies from 'vue-cookies';

export default {
  components: {
    VueEasyLightbox
  },
  props: {
    comments: Array,
    images: Array,
    user: Object,
    request: Object
  },
  data() {
    return {
      appVersion: packageInfo.version,
      authToken: null,
      commentsList: null,
      echoServer: null,
    }
  },
  mounted() {
    this.commentsList = this.comments;
    if (this.request) {
      this.authToken = VueCookies.get('jwt-token');

      let self = this;
      this.echoServer = new Echo({
        broadcaster: "socket.io",
        host: window.location.hostname,
        transports: ["websocket", "poling"],
        auth: {
          headers: {
            Authorization: "Bearer " + this.authToken
          }
        }
      });
      this.echoServer
      .private(process.env.BROADCAST_CHANNELS_PREFIX + "_requests_comments_update." + this.user.id + "." + this.request.id)
      .listen("RequestsCommentsBroadcast", function (e) {
        self.checkCommentFromBroadcast(e);
      });

      this.echoServer.connector.socket.on('connect', function () {});
    }
  },
  setup(props) {
    const visibleRef = ref(false);
    const indexRef = ref(0);
    const imgsRef = ref([]);

    let showImage = (file) => {
      imgsRef.value = props.images;
      let image = props.images.find(x => x.id === file.id);
      indexRef.value = props.images.indexOf(image);
      visibleRef.value = true;
    }
    const closeImage = () => (visibleRef.value = false)

    return {
      visibleRef,
      indexRef,
      imgsRef,
      showImage,
      closeImage
    }
  },
  methods: {
    checkCommentFromBroadcast(e) {
      if (e.type === 'store') {
        this.commentsList.push(e.comment);
        this.$emit('changeImages', e.comment.allRequestImages);
      } else if (e.type === 'update') {
        let comment = this.commentsList.find(x => x.id === e.comment.id);
        if (comment) {
          let indexOfComment = this.commentsList.indexOf(comment);
          if (indexOfComment > -1) {
            this.commentsList[indexOfComment] = e.comment;
            this.$emit('changeImages', e.comment.allRequestImages);
          }
        }

      } else if (e.type === 'delete') {
        let comment = this.commentsList.find(x => x.id === e.comment.id);
        if (comment) {
          let indexOfComment = this.commentsList.indexOf(comment);
          if (indexOfComment > -1) {
            this.commentsList.splice(indexOfComment, 1);
            this.$emit('changeImages', e.comment.allRequestImages);
          }
        }

      }
      this.$emit('scrollToEnd');
    },
    editComment(comment) {
      this.$emit('editComment', comment);
    },
    deleteComment(id) {
      if (confirm('Удалить этот комментарий?')) {
        this.$emit('deleteComment', id);
      }
    },
    deleteFile(file) {
      if (confirm('Удалить этот файл?')) {
        if (file.id) {
          this.$emit('deleteFile', file.id);
        } else {
          this.$emit('deleteFile', file.hashname);
        }
      }
    },
    wrapAnchorTag(text, skipAnchor = null) {
      if (text) {
        text = "<p>" + text + "</p>";
        let regexLink = /(http|ftp|https:\/\/[\w\\А-я\-_]+\.{1}[\w\\А-я\-_]+)+([\w\\А-я\-\.,@?^=%&amp;:/~\+#]*[\w\\А-я\-\@?^=%&amp;/~\+#])?/gi;
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if (!skipAnchor) {
          text = text.replace(regexLink,'<a href="$1$2" target="_blank">$1$2</a>');
        }
        return text;
      }
    },
    selectAnswerTo(id) {
      this.$emit('selectAnswerTo', id);
    },
  },
  watch: {
    comments(newVal) {
      this.commentsList = newVal;
    }
  },
  beforeUnmount() {
    if (this.echoServer) {
      this.echoServer.leave(process.env.BROADCAST_CHANNELS_PREFIX + "_requests_comments_update." + this.user.id + "." + this.request.id);
    }
  }
};
</script>