<template>
  <div class="main__wrap">
    <form ref="form" @submit="$refs.form.submit()" target="_blank" method="post" action="/settings/reports" class="main__content content content--wide">
      <input type="hidden" name="_token" :value="reports.csrf_token" autocomplete="off">
      <div class="content__top">
        <div class="title">Отчёты</div>
      </div>

      <div class="content__main">
        <div class="content__body">
          <div class="content__block">
            <div class="row row--aife">
              <div class="col col--half">
                <div class="task-teas__header"><div class="task-teas__title">Задачи по проектам</div></div>
              </div>
            </div>

            <div class="row row--aife">
              <div class="col col--half">
                <div class="form-group">
                  <label for="projects" class="form-label">Проект</label>
                  <div class="form-field form-field--select form-field--2 form-field--m form-field--primary">
                    <select id="projects" ref="projects" name="projects[]" class="form-control" multiple>
                      <option data-placeholder="true">Все проекты</option>
                      <option v-for="project in reports.projects" :value="project.id">{{project.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="ready" class="row">
              <div class="col col--fourth">
                <div class="form-group">
                  <label for="start_date" class="form-label">Начальная дата</label>
                  <div class="date-picker form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <VueDatePicker id="start_date" v-model="start_date" locale="ru" name="start_date"
                                   :model-type="'yyyy-MM-dd'" auto-apply :enable-time-picker="false"
                                   :teleport="true" :dark="reports.currentUser.setting.theme === 'dark'"
                                   :clearable="true" cancelText="Отмена" selectText="Выбрать" :format="dateFormat"
                                   input-class-name="form-control"></VueDatePicker>
                  </div>
                </div>
              </div>
              <div class="col col--fourth">
                <div class="form-group">
                  <label for="start_time" class="form-label">Конечная дата</label>
                  <div class="date-picker form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <VueDatePicker id="start_date" v-model="end_date" locale="ru" name="end_date"
                                   :model-type="'yyyy-MM-dd'" auto-apply :enable-time-picker="false"
                                   :teleport="true" :dark="reports.currentUser.setting.theme === 'dark'"
                                   :clearable="true" cancelText="Отмена" selectText="Выбрать" :format="dateFormat"
                                   input-class-name="form-control"></VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row--aife">
              <div class="col col--fourth">
                <div class="btns-wrap btns-wrap--sb">
                  <button class="btn btn--4 btn--m btn--primary" type="submit">
                    <span class="btn__label">Сформировать</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SlimSelect from 'slim-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {ref} from 'vue';

export default {
  components: {VueDatePicker},
  props: {
    reports: Object
  },
  data() {
    return {
      projectsSelect: null,
      dateFormat: null,
      start_date: null,
      end_date: null,
      ready: false
    }
  },
  mounted() {
    const date = ref(new Date());
    this.dateFormat = (date) => {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }

    this.projectsSelect = new SlimSelect({
      select: this.$refs['projects'],
      settings: {
        showSearch: false,
        searchPlaceholder: 'Поиск проекта',
        searchText: 'Проекты не найдены',
        searchingText: 'Идёт поиск...',
        allowDeselect: false
      }
    });

    this.ready = true;
  }
};
</script>