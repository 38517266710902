<template>
  <div class="main__wrap">
    <div class="main__content content">
      <div class="content__top content__top_link">
        <div class="title">Проекты</div>

        <div class="content__flex">
          <div class="form-group">
            <div class="form-field form-field--text form-field--2 form-field--s form-field--primary">
              <div class="form-field__side form-field__side--left">
                <span class="icon icon--s form-icon">
                  <svg class="svg" width="16" height="16">
                    <use :href="'/img/sprite-' + appVersion + '.svg#search-16'"/>
                  </svg>
                </span>
              </div>

              <input id="search-projects" @keyup="searchProjectsKeyUp" v-model="search" type="text" class="form-control" placeholder="Поиск по проектам">
            </div>
          </div>

          <a href="/settings/projects/create" class="btn btn--4 btn--m btn--primary">
            <span class="btn__label">+ Добавить проект</span>
          </a>
        </div>
      </div>
      <div v-if="projectsList && projectsList.data.length > 0" class="content__main">
        <div class="content__body">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
              <tr>
                <th>Название</th>
                <th>Дата&nbsp;создания</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="project of projectsList.data">
                <td>
                  <a :href="'/settings/projects/' + project.id" class="col-main col-link">{{project.name}}</a>
                </td>
                <td><span class="col-extra">{{project.date}}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="projectsList && projectsList.data.length === 0" class="content__main content__main--center">
        <div class="content__body">
          <div class="plug-box">
            <div class="plug-box__cover">
              <img src="/img/plug-no-tasks.svg" width="574" height="231" alt="" class="plug-box__img">
            </div>

            <div class="plug-box__text">Активных проектов нет</div>
          </div>
        </div>
      </div>

      <div v-if="projectsList" class="content__bottom">
        <Pagination :items="projectsList"></Pagination>
      </div>
    </div>
  </div>
  <div v-if="!projectsList" class="preloader preloader--page is-visible">
    <div class="preloader__spinner">
      <svg class="preloader-cover" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <rect fill="#006DFF" class="spinner_LWk7" x="1.5" y="1.5" rx="1" width="9" height="9" />
        <rect fill="#368CFF" class="spinner_yOMU" x="13.5" y="1.5" rx="1" width="9" height="9" />
        <rect fill="#006DFF" class="spinner_KS4S" x="13.5" y="13.5" rx="1" width="9" height="9" />
        <rect fill="#368CFF" class="spinner_zVee" x="1.5" y="13.5" rx="1" width="9" height="9" />
      </svg>
    </div>
  </div>
</template>

<script>
import Pagination from '../Pagination/Pagination.vue';
import packageInfo from '../../../../package.json';
import {debounce} from '../_Functions/debounce';

export default {
  components: {
    Pagination,
  },
  props: {
    projects: Array
  },
  data() {
    return {
      appVersion: packageInfo.version,
      projectsList: null,
      search: null,
      debounceFunction: null,
    }
  },
  mounted() {
    this.projectsList = this.projects;
    const urlParams = new URLSearchParams(window.location.search);
    this.search = urlParams.get('search');
    document.getElementById('search-projects').focus();
    document.getElementById('search-projects').select();

    this.debounceFunction = debounce(() => this.searchProjects(), 500)
  },
  methods: {
    searchProjectsKeyUp() {
      this.debounceFunction();
    },
    searchProjects() {
      let url = new URL(location.href);
      let searchParams = url.searchParams;
      if (this.search) {
        searchParams.set('search', this.search);
      } else {
        searchParams.delete('search');
      }
      searchParams.set('page', '1');
      url.search = searchParams.toString();
      location.href = url.toString();
    }
  }
};
</script>